import { Comment } from "@app/entities/comments";
import { User } from "@app/entities/users";
import { DocumentService } from "@app/types-business/documents";
import { isEmpty, get, filter } from "@app/utils/lodash";
import { CustomTicketTypeConfiguration } from "./customTypes";
import { SettingsEntity } from "../settings";
import { DocumentHistoryEvent, getDocumentId } from "../document";
import { TicketSearchResult } from "../document/search-result";

const OneMegaByteInBytes = 1048576;

export const CustomTicketTypePrefix = "CUSTOM_TYPE_";
export const MaxTicketAttachmentSize = OneMegaByteInBytes * 50;

export enum TicketPriority {
  Low = "LOW",
  Medium = "MEDIUM",
  High = "HIGH",
  Critical = "CRITICAL",
}

export enum TicketType {
  Contract = "CONTRACT",
  General = "GENERAL",
  Amendment = "AMENDMENT",
}

export enum TicketStatus {
  Todo = "TODO",
  InProgress = "IN_PROGRESS",
  Waiting = "WAITING",
  Done = "DONE",
  Canceled = "CANCELED",
}

export enum TicketSourceType {
  EmailRequest = "EMAIL_REQUEST",
}

export interface TicketRelations {
  document: DocumentService.ReviewDocument | string | null;
  otherDocuments: DocumentService.Document[];
  followers: User[];
  tickets: Ticket[];
}

export interface TicketAttachment {
  createdBy: User;
  createdDate: string;
  fileId: string;
  filename: string;
  mediaType: string;
}

export interface Ticket {
  assignee?: User;
  attachments: TicketAttachment[];
  comments: Comment[];
  createdBy: User;
  createdDate: string;
  ticketType?: SettingsEntity<CustomTicketTypeConfiguration>;
  dueDate?: string;
  id: string;
  key: string;
  priority: TicketPriority;
  properties: {
    reviewProperties?: {
      documentId?: string;
    };
    customTicketTypeProperties: any;
    hubspot?: {
      hubspotObjectId?: string;
      hubspotObjectType?: string;
      hubspotPortalId?: string;
    };
  };
  relatedTicketIds: string[];
  relations: TicketRelations;
  status: TicketStatus;
  summary: string;
  title: string;
  type: TicketType | string;
  updatedBy: User;
  updatedDate: string;
}

export const getTicketId = (ticket: Ticket) => get(ticket, "ticketId");

export const getTicketTitle = (ticket: Ticket) => get(ticket, "title") ?? "";

export const getTicketType = (ticket: Ticket): TicketType | string =>
  get(ticket, "type");

export const getTicketCustomType = (
  ticket: Ticket
): CustomTicketTypeConfiguration => get(ticket, "ticketType.data");

export const isCustomTicketType = (ticket: Ticket) => {
  const type = getTicketType(ticket) || "";
  return type.startsWith(CustomTicketTypePrefix);
};

export const getCustomTicketTypeId = (ticket: Ticket) => {
  if (!isCustomTicketType(ticket)) return null;
  return getTicketType(ticket).replace(CustomTicketTypePrefix, "");
};

export const getKey = (ticket: Ticket) => ticket?.key;

export const getAttachments = (ticket: Ticket) => ticket?.attachments ?? [];

export const hasAttachments = (ticket: Ticket) =>
  !isEmpty(getAttachments(ticket));

export const getTicketRelations = (ticket: Ticket): TicketRelations =>
  ticket?.relations || null;

export const getTicketDocument = (ticket: Ticket) => {
  const document = getTicketRelations(ticket)?.document;
  // if we don't have a document in the relation, try the legacy location in properties.reviewProperties
  if (!document || typeof document === "string")
    return get(ticket, "properties.reviewProperties.document");
  return document;
};

export const getReviewTimeline = (ticket: Ticket): DocumentHistoryEvent[] =>
  get(ticket, "properties.reviewProperties.timeline") || [];

export const getSummary = (ticket: Ticket): string => {
  const summary = get(ticket, "summary") || "";
  return summary.trim();
};

export const getPriority = (ticket: Ticket): TicketPriority =>
  get(ticket, "priority") || null;

export const getTicketStatus = (ticket: Ticket): TicketStatus =>
  get(ticket, "status") || null;

export const getAssignee = (ticket: Ticket): User | null =>
  get(ticket, "assignee") || null;

export const getCreator = (ticket: Ticket): User | null =>
  get(ticket, "createdBy") || null;

export const isTicketCanceled = (ticket: Ticket): boolean =>
  getTicketStatus(ticket) === TicketStatus.Canceled;

export const getFollowers = (ticket: Ticket): User[] | null =>
  get(ticket, "relations.followers") || [];

export const getTicketDueDate = (ticket: Ticket): string =>
  get(ticket, "dueDate") || "";

export const getTicketTypeProperties = (ticket: Ticket) =>
  get(ticket, "properties.customTicketTypeProperties") ?? {};

export const getRelatedTickets = (ticket: Ticket) =>
  filter(getTicketRelations(ticket)?.tickets, (t) => !!getTicketId(t)); // filter not resolved or deleted tickets

export const getRelatedDocuments = (ticket: Ticket) =>
  filter(getTicketRelations(ticket)?.otherDocuments, (d) => !!getDocumentId(d)); // filter not resolved or deleted documents

export function getTicketHubspotDealProperties(ticket: Ticket) {
  const { hubspotObjectType, hubspotObjectId, hubspotPortalId } =
    ticket?.properties?.hubspot || {};
  if (hubspotObjectType !== "deals") return {};
  return { hubspotDealId: hubspotObjectId, hubspotPortalId };
}

/**
 * We have components where the ticket prop is either a ticket state object or a ticket search result object that
 * contains the ticket state object. This convenience function returns the ticket state object from either.
 */
export function asTicket(ticket: Ticket | TicketSearchResult): Ticket {
  return (ticket as any).objectState
    ? (ticket as TicketSearchResult).objectState
    : (ticket as Ticket);
}
